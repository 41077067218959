// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UpdateCountryRequest from "./requests/UpdateCountryRequest.res.js";
import * as FetchCountriesRequest from "./requests/FetchCountriesRequest.res.js";
import * as FixCountryCountsRequest from "./requests/FixCountryCountsRequest.res.js";
import * as FetchAllCountriesRequest from "./requests/FetchAllCountriesRequest.res.js";
import * as UpdateCountryActiveRequest from "./requests/UpdateCountryActiveRequest.res.js";
import * as FetchCountriesDashboardRequest from "./requests/FetchCountriesDashboardRequest.res.js";
import * as UpdateCountryActiveInBatchRequest from "./requests/UpdateCountryActiveInBatchRequest.res.js";

var fetchCountries = FetchCountriesRequest.exec;

var fetchAllCountries = FetchAllCountriesRequest.exec;

var fetchCountriesDashboard = FetchCountriesDashboardRequest.exec;

var updateActive = UpdateCountryActiveRequest.exec;

var updateActiveInBatch = UpdateCountryActiveInBatchRequest.exec;

var fixCounts = FixCountryCountsRequest.exec;

var update = UpdateCountryRequest.exec;

export {
  fetchCountries ,
  fetchAllCountries ,
  fetchCountriesDashboard ,
  updateActive ,
  updateActiveInBatch ,
  fixCounts ,
  update ,
}
/* UpdateCountryRequest Not a pure module */
