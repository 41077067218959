// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Col from "../../../../../styleguide/components/Grid/Col/Col.res.js";
import * as Row from "../../../../../styleguide/components/Grid/Row/Row.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as Grid from "../../../../../styleguide/components/Grid/Grid.res.js";
import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as Quill from "../../../../../bindings/Quill.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Editor from "../../../../../styleguide/forms/Editor/Editor.res.js";
import * as Select from "../../../../../styleguide/forms/Select/Select.res.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as Toggle from "../../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as Country from "../../../../../models/Country.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as FormTags from "../../../../../styleguide/components/FormTags/FormTags.res.js";
import * as Keyboard from "../../../../../libs/Keyboard.res.js";
import * as Textarea from "../../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Api_Country from "../../../../../api/countries/Api_Country.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ErrorMessage from "../../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as Routes_Country from "../../../../../routes/common/Routes_Country.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CountryEditFormScss from "./CountryEditForm.scss";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";

var css = CountryEditFormScss;

var validators_administrativeDivision = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = Country.divisionToString(input.administrativeDivision);
      if (x.trim() === "") {
        return {
                TAG: "Error",
                _0: "Administrative division is required."
              };
      } else {
        return {
                TAG: "Ok",
                _0: Country.divisionToString(input.administrativeDivision)
              };
      }
    })
};

var validators_metaDescription = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.metaDescription.length;
      if (length > 500) {
        return {
                TAG: "Error",
                _0: "Meta description must no more than 500 characters."
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.metaDescription
              };
      }
    })
};

var validators_metaTitle = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.metaTitle.length;
      if (length > 200) {
        return {
                TAG: "Error",
                _0: "Meta title must no more than 200 characters."
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.metaTitle
              };
      }
    })
};

var validators = {
  altNames: undefined,
  administrativeDivision: validators_administrativeDivision,
  cityState: undefined,
  active: undefined,
  metaDescription: validators_metaDescription,
  metaTitle: validators_metaTitle
};

function initialFieldsStatuses(_input) {
  return {
          altNames: "Pristine",
          administrativeDivision: "Pristine",
          cityState: "Pristine",
          active: "Pristine",
          metaDescription: "Pristine",
          metaTitle: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match_0 = {
    TAG: "Ok",
    _0: input.altNames
  };
  var match = fieldsStatuses.administrativeDivision;
  var tmp;
  tmp = typeof match !== "object" ? validators.administrativeDivision.validate(input) : match._0;
  var match_0$1 = {
    TAG: "Ok",
    _0: input.cityState
  };
  var match_0$2 = {
    TAG: "Ok",
    _0: input.active
  };
  var match$1 = fieldsStatuses.metaDescription;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.metaDescription.validate(input) : match$1._0;
  var match$2 = fieldsStatuses.metaTitle;
  var tmp$2;
  tmp$2 = typeof match$2 !== "object" ? validators.metaTitle.validate(input) : match$2._0;
  var altNamesResult = match_0;
  var altNamesResult$1;
  if (altNamesResult.TAG === "Ok") {
    var administrativeDivisionResult = tmp;
    if (administrativeDivisionResult.TAG === "Ok") {
      var cityStateResult = match_0$1;
      if (cityStateResult.TAG === "Ok") {
        var activeResult = match_0$2;
        if (activeResult.TAG === "Ok") {
          var metaDescriptionResult = tmp$1;
          if (metaDescriptionResult.TAG === "Ok") {
            var metaTitleResult = tmp$2;
            if (metaTitleResult.TAG === "Ok") {
              return {
                      TAG: "Valid",
                      output: {
                        metaTitle: metaTitleResult._0,
                        metaDescription: metaDescriptionResult._0,
                        active: activeResult._0,
                        cityState: cityStateResult._0,
                        administrativeDivision: administrativeDivisionResult._0,
                        altNames: altNamesResult._0
                      },
                      fieldsStatuses: {
                        altNames: {
                          TAG: "Dirty",
                          _0: altNamesResult,
                          _1: "Hidden"
                        },
                        administrativeDivision: {
                          TAG: "Dirty",
                          _0: administrativeDivisionResult,
                          _1: "Shown"
                        },
                        cityState: {
                          TAG: "Dirty",
                          _0: cityStateResult,
                          _1: "Hidden"
                        },
                        active: {
                          TAG: "Dirty",
                          _0: activeResult,
                          _1: "Hidden"
                        },
                        metaDescription: {
                          TAG: "Dirty",
                          _0: metaDescriptionResult,
                          _1: "Shown"
                        },
                        metaTitle: {
                          TAG: "Dirty",
                          _0: metaTitleResult,
                          _1: "Shown"
                        }
                      },
                      collectionsStatuses: undefined
                    };
            }
            altNamesResult$1 = altNamesResult;
          } else {
            altNamesResult$1 = altNamesResult;
          }
        } else {
          altNamesResult$1 = altNamesResult;
        }
      } else {
        altNamesResult$1 = altNamesResult;
      }
    } else {
      altNamesResult$1 = altNamesResult;
    }
  } else {
    altNamesResult$1 = altNamesResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            altNames: {
              TAG: "Dirty",
              _0: altNamesResult$1,
              _1: "Hidden"
            },
            administrativeDivision: {
              TAG: "Dirty",
              _0: tmp,
              _1: "Shown"
            },
            cityState: {
              TAG: "Dirty",
              _0: match_0$1,
              _1: "Hidden"
            },
            active: {
              TAG: "Dirty",
              _0: match_0$2,
              _1: "Hidden"
            },
            metaDescription: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            },
            metaTitle: {
              TAG: "Dirty",
              _0: tmp$2,
              _1: "Shown"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurAltNamesField" :
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.altNames, state.fieldsStatuses.altNames, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  altNames: status,
                                  administrativeDivision: init.administrativeDivision,
                                  cityState: init.cityState,
                                  active: init.active,
                                  metaDescription: init.metaDescription,
                                  metaTitle: init.metaTitle
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurAdministrativeDivisionField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.administrativeDivision, validators_administrativeDivision, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  altNames: init.altNames,
                                  administrativeDivision: status,
                                  cityState: init.cityState,
                                  active: init.active,
                                  metaDescription: init.metaDescription,
                                  metaTitle: init.metaTitle
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurCityStateField" :
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.cityState, state.fieldsStatuses.cityState, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  altNames: init.altNames,
                                  administrativeDivision: init.administrativeDivision,
                                  cityState: status,
                                  active: init.active,
                                  metaDescription: init.metaDescription,
                                  metaTitle: init.metaTitle
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurActiveField" :
                  var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.active, state.fieldsStatuses.active, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  altNames: init.altNames,
                                  administrativeDivision: init.administrativeDivision,
                                  cityState: init.cityState,
                                  active: status,
                                  metaDescription: init.metaDescription,
                                  metaTitle: init.metaTitle
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurMetaDescriptionField" :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.metaDescription, validators_metaDescription, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  altNames: init.altNames,
                                  administrativeDivision: init.administrativeDivision,
                                  cityState: init.cityState,
                                  active: init.active,
                                  metaDescription: status,
                                  metaTitle: init.metaTitle
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurMetaTitleField" :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.metaTitle, validators_metaTitle, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  altNames: init.altNames,
                                  administrativeDivision: init.administrativeDivision,
                                  cityState: init.cityState,
                                  active: init.active,
                                  metaDescription: init.metaDescription,
                                  metaTitle: status
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateAltNamesField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.altNames, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            altNames: status,
                                            administrativeDivision: init.administrativeDivision,
                                            cityState: init.cityState,
                                            active: init.active,
                                            metaDescription: init.metaDescription,
                                            metaTitle: init.metaTitle
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateAdministrativeDivisionField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.administrativeDivision, state.submissionStatus, validators_administrativeDivision, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            altNames: init.altNames,
                                            administrativeDivision: status,
                                            cityState: init.cityState,
                                            active: init.active,
                                            metaDescription: init.metaDescription,
                                            metaTitle: init.metaTitle
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateCityStateField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.cityState, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            altNames: init.altNames,
                                            administrativeDivision: init.administrativeDivision,
                                            cityState: status,
                                            active: init.active,
                                            metaDescription: init.metaDescription,
                                            metaTitle: init.metaTitle
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateActiveField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.active, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            altNames: init.altNames,
                                            administrativeDivision: init.administrativeDivision,
                                            cityState: init.cityState,
                                            active: status,
                                            metaDescription: init.metaDescription,
                                            metaTitle: init.metaTitle
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateMetaDescriptionField" :
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.metaDescription, state.submissionStatus, validators_metaDescription, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            altNames: init.altNames,
                                            administrativeDivision: init.administrativeDivision,
                                            cityState: init.cityState,
                                            active: init.active,
                                            metaDescription: status,
                                            metaTitle: init.metaTitle
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateMetaTitleField" :
                  var nextInput$5 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.metaTitle, state.submissionStatus, validators_metaTitle, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            altNames: init.altNames,
                                            administrativeDivision: init.administrativeDivision,
                                            cityState: init.cityState,
                                            active: init.active,
                                            metaDescription: init.metaDescription,
                                            metaTitle: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateAltNames: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateAltNamesField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateAdministrativeDivision: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateAdministrativeDivisionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateCityState: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateCityStateField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateActive: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateActiveField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateMetaDescription: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateMetaDescriptionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateMetaTitle: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateMetaTitleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurAltNames: (function () {
              dispatch("BlurAltNamesField");
            }),
          blurAdministrativeDivision: (function () {
              dispatch("BlurAdministrativeDivisionField");
            }),
          blurCityState: (function () {
              dispatch("BlurCityStateField");
            }),
          blurActive: (function () {
              dispatch("BlurActiveField");
            }),
          blurMetaDescription: (function () {
              dispatch("BlurMetaDescriptionField");
            }),
          blurMetaTitle: (function () {
              dispatch("BlurMetaTitleField");
            }),
          altNamesResult: Formality.exposeFieldResult(state.fieldsStatuses.altNames),
          administrativeDivisionResult: Formality.exposeFieldResult(state.fieldsStatuses.administrativeDivision),
          cityStateResult: Formality.exposeFieldResult(state.fieldsStatuses.cityState),
          activeResult: Formality.exposeFieldResult(state.fieldsStatuses.active),
          metaDescriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.metaDescription),
          metaTitleResult: Formality.exposeFieldResult(state.fieldsStatuses.metaTitle),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.altNames;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.administrativeDivision;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.cityState;
              if (typeof tmp$2 === "object") {
                return true;
              }
              var tmp$3 = match.active;
              if (typeof tmp$3 === "object") {
                return true;
              }
              var tmp$4 = match.metaDescription;
              if (typeof tmp$4 === "object") {
                return true;
              }
              var tmp$5 = match.metaTitle;
              if (typeof tmp$5 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var CountryEditForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function reducer(state, action) {
  if (action.TAG === "SetEditorState") {
    return {
            editorState: Editor.State.compose(state.editorState, action._0),
            altName: state.altName
          };
  } else {
    return {
            editorState: state.editorState,
            altName: action._0
          };
  }
}

function CountryEditForm$1(props) {
  var country = props.country;
  var initialState_editorState = Editor.State.make();
  var initialState = {
    editorState: initialState_editorState,
    altName: ""
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var initialInput = React.useMemo((function () {
          return {
                  metaTitle: country.metaTitle,
                  metaDescription: country.metaDescription,
                  active: country.active,
                  cityState: country.cityState,
                  administrativeDivision: country.administrativeDivision,
                  altNames: country.altNames
                };
        }), []);
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_Country.update(country.id, document.querySelector(".ql-editor").innerHTML, output.metaTitle, output.metaDescription, output.active, output.cityState, output.altNames, output.administrativeDivision), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Country.Dashboard.edit(x._0.country.id));
                  } else {
                    return SentryLogger.error1({
                                rootModule: "CountryEditForm",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "CountryEditForm.make"
                              }, "EditCountry::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  className: css.error,
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var containerRef = React.useRef(null);
  var editorRef = React.useRef(undefined);
  React.useEffect((function () {
          var editor = Belt_Option.getExn(Belt_Option.map(Caml_option.nullable_to_opt(containerRef.current), (function (container) {
                      return Quill.make("Type here...", undefined, undefined, Quill.countryToolbarOptions, {}, undefined, container);
                    })));
          editor.clipboard.dangerouslyPasteHTML(0, country.description);
          var listener = function (delta, param) {
            dispatch({
                  TAG: "SetEditorState",
                  _0: delta
                });
          };
          editorRef.current = Caml_option.some(editor);
          editor.on("text-change", listener);
          return (function () {
                    editor.off("text-change", listener);
                  });
        }), []);
  var addAltName = function (altName) {
    var capitalized = Belt_Array.map(altName.split(" "), $$String.capitalize_ascii).join(" ");
    if (!form.input.altNames.includes(capitalized) && capitalized !== "") {
      form.input.altNames.push(capitalized);
      return form.updateAltNames((function (input, value) {
                    return {
                            metaTitle: input.metaTitle,
                            metaDescription: input.metaDescription,
                            active: input.active,
                            cityState: input.cityState,
                            administrativeDivision: input.administrativeDivision,
                            altNames: value
                          };
                  }), form.input.altNames);
    }
    
  };
  return JsxRuntime.jsx("form", {
              children: JsxRuntime.jsxs(Grid.make, {
                    className: css.grid,
                    children: [
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "country-form--active",
                                          children: "Active"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(Toggle.make, {
                                            on: form.input.active,
                                            size: "MD",
                                            onChange: (function () {
                                                form.updateActive((function (input, value) {
                                                        return {
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: input.metaDescription,
                                                                active: value,
                                                                cityState: input.cityState,
                                                                administrativeDivision: input.administrativeDivision,
                                                                altNames: input.altNames
                                                              };
                                                      }), !form.input.active);
                                              })
                                          }),
                                      form.input.active ? JsxRuntime.jsx("div", {
                                              children: "This country currently has " + String(country.locationsCountActive) + "\n                  active locations. Setting it to \"Inactive\" will remove it from\n                  public access.",
                                              className: css.fieldInstructions
                                            }) : JsxRuntime.jsx("div", {
                                              children: "This country currently has " + String(country.locationsCountActive) + "\n                  active locations. Setting it to \"Active\" will make it public.",
                                              className: css.fieldInstructions
                                            })
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "country-form--cityState",
                                          children: "City State"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(Toggle.make, {
                                            on: form.input.cityState,
                                            size: "MD",
                                            onChange: (function () {
                                                form.updateCityState((function (input, value) {
                                                        return {
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: input.metaDescription,
                                                                active: input.active,
                                                                cityState: value,
                                                                administrativeDivision: input.administrativeDivision,
                                                                altNames: input.altNames
                                                              };
                                                      }), !form.input.cityState);
                                              })
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "If true, any state or city pages related to this country, will be redirected\n              to the country page.",
                                            className: css.fieldInstructions
                                          })
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "country-form--country-description",
                                          children: "Description"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(Editor.make, {
                                                ref: containerRef
                                              }),
                                          className: css.editor
                                        })
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "country-form--seo-title",
                                          children: "Meta Title"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "country-form--seo-title",
                                            value: form.input.metaTitle,
                                            placeholder: "Add a meta title.",
                                            onChange: (function ($$event) {
                                                form.updateMetaTitle((function (input, value) {
                                                        return {
                                                                metaTitle: value,
                                                                metaDescription: input.metaDescription,
                                                                active: input.active,
                                                                cityState: input.cityState,
                                                                administrativeDivision: input.administrativeDivision,
                                                                altNames: input.altNames
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.metaTitleResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "country-form--meta-description",
                                          children: "Meta Description"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(Textarea.make, {
                                            id: "country-form--meta-description",
                                            value: form.input.metaDescription,
                                            placeholder: "Add a meta description.",
                                            onChange: (function ($$event) {
                                                form.updateMetaDescription((function (input, value) {
                                                        return {
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: value,
                                                                active: input.active,
                                                                cityState: input.cityState,
                                                                administrativeDivision: input.administrativeDivision,
                                                                altNames: input.altNames
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.metaDescriptionResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "country-form--alt-names",
                                          children: "Alternative Names"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "country-form--alt-names",
                                            value: state.altName,
                                            placeholder: "Add a new name. Hit a comma to add the name.",
                                            onChange: (function ($$event) {
                                                var value = $$event.target.value.replace(new RegExp(","), "");
                                                dispatch({
                                                      TAG: "SetAltName",
                                                      _0: value
                                                    });
                                              }),
                                            onKeyUp: (function ($$event) {
                                                Keyboard.React.onComma($$event, (function () {
                                                        addAltName(state.altName);
                                                        dispatch({
                                                              TAG: "SetAltName",
                                                              _0: ""
                                                            });
                                                      }));
                                              })
                                          }),
                                      JsxRuntime.jsx(FormTags.make, {
                                            tags: form.input.altNames,
                                            updateTags: (function (altName) {
                                                return function (param) {
                                                  var altNames = Belt_Array.keep(form.input.altNames, (function (item) {
                                                          return item !== altName;
                                                        }));
                                                  form.updateAltNames((function (input, value) {
                                                          return {
                                                                  metaTitle: input.metaTitle,
                                                                  metaDescription: input.metaDescription,
                                                                  active: input.active,
                                                                  cityState: input.cityState,
                                                                  administrativeDivision: input.administrativeDivision,
                                                                  altNames: value
                                                                };
                                                        }), altNames);
                                                };
                                              })
                                          }),
                                      fieldError(form.altNamesResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "country--administrativeDivision",
                                          children: "Administrative Division"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: JsxRuntime.jsx(Select.make, {
                                          id: "country--administrativeDivision",
                                          value: Country.divisionToString(form.input.administrativeDivision),
                                          onChange: (function ($$event) {
                                              form.updateAdministrativeDivision((function (input, value) {
                                                      return {
                                                              metaTitle: input.metaTitle,
                                                              metaDescription: input.metaDescription,
                                                              active: input.active,
                                                              cityState: input.cityState,
                                                              administrativeDivision: value,
                                                              altNames: input.altNames
                                                            };
                                                    }), Country.divisionFromString($$event.target.value));
                                            }),
                                          children: Belt_Array.mapWithIndex(Country.administriveDivisions, (function (index, division) {
                                                  return JsxRuntime.jsx(Select.$$Option.make, {
                                                              value: Country.divisionToString(division),
                                                              children: Country.divisionToString(division)
                                                            }, "administrative-division-" + String(index));
                                                }))
                                        })
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.buttonHiddenContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx("div", {})
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.buttonCreateContainer,
                                    md: 4,
                                    lg: 4,
                                    children: JsxRuntime.jsx(Button.make, {
                                          size: "LG",
                                          color: "Primary",
                                          expanded: true,
                                          disabled: form.submitting,
                                          busy: form.submitting,
                                          submit: true,
                                          children: "Save Country"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.buttonCancelContainer,
                                    md: 5,
                                    lg: 5,
                                    children: JsxRuntime.jsx(Button.make, {
                                          size: "LG",
                                          color: "White",
                                          expanded: true,
                                          busy: false,
                                          submit: true,
                                          onClick: (function (e) {
                                              e.preventDefault();
                                              Url.visit(Routes_Country.Dashboard.index);
                                            }),
                                          children: "Cancel"
                                        })
                                  })
                            ]
                          })
                    ]
                  }),
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  form.submit();
                })
            });
}

var make = CountryEditForm$1;

export {
  css ,
  CountryEditForm ,
  reducer ,
  make ,
}
/* css Not a pure module */
