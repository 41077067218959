// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as CountryEditForm from "./components/CountryEditForm.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CountryEditScss from "./CountryEdit.scss";
import * as CountriesDashboardNavbar from "../index/components/CountriesDashboardNavbar.res.js";

var css = CountryEditScss;

function CountryEdit$default(props) {
  var country = props.country;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(CountriesDashboardNavbar.make, {
                      currentLink: "Edit",
                      country: country
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: css.container,
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              className: css.pageTitle,
                              children: "Edit Country: " + country.name
                            }),
                        JsxRuntime.jsx(CountryEditForm.make, {
                              country: country
                            })
                      ]
                    })
              ],
              className: css.pageContainer
            });
}

var $$default = CountryEdit$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
