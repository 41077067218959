// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as Country from "../../../models/Country.res.js";

function exec() {
  return Rest.$$fetch("/locations/countries/all", "Get", {
              NAME: "Json",
              VAL: Country.List.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* Rest Not a pure module */
