// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Country from "../../../models/Country.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(active) {
  return Json_Encode$JsonCombinators.object([[
                "active",
                Country.Active.toJson(active)
              ]]);
}

var Req = {
  toJson: toJson
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              country: field.required("country", Country.Dashboard.decoder)
            };
    });

var Res = {
  decoder: decoder
};

function exec(countryId, active) {
  return Rest.$$fetch("/dashboard/countries/" + (ID.toString(countryId) + "/active"), {
              NAME: "Patch",
              VAL: toJson(active)
            }, {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
